export default class ScrollableNav {
    constructor(id: string, containerId: string) {
        let container: HTMLElement = document.getElementById(containerId);
        let contentWidth: number = container.offsetWidth;

        function checkScrollbar() {
            let nav: HTMLElement = document.getElementById(id)
            let containerWidth: number = nav.clientWidth;
            if (contentWidth > containerWidth) {
                nav.classList.add("show-scroll");
            } else {
                nav.classList.remove("show-scroll");
            }
        }

        window.onresize = () => {
            checkScrollbar();
        }

        container.addEventListener('wheel', (event) => {
            if (event.deltaY > 0) {
                container.scrollLeft += 50;
            } else {
                container.scrollLeft -= 50;
            }
            event.preventDefault();
        });
    }
}
