export default class CardDropdown {
    constructor(id: string) {
        let card: HTMLElement = document.getElementById(id);
        card.querySelectorAll('.staff-dropdown-toggle').forEach(function (button: HTMLButtonElement) {
            button.addEventListener('click', function () {
                const targetId: string = button.getAttribute('data-target');
                const targetElement: HTMLElement = document.querySelector(targetId);

                if (targetElement) {
                    if (targetElement.style.display === 'none' || targetElement.style.display === '') {
                        targetElement.style.display = 'block';
                        targetElement.style.maxHeight = targetElement.scrollHeight + 'px';
                        button.firstElementChild.classList.remove("is-tail-down")
                        button.firstElementChild.classList.add("is-arrow-up")
                    } else {
                        targetElement.style.maxHeight = '0';
                        button.firstElementChild.classList.remove("is-arrow-up")
                        button.firstElementChild.classList.add("is-tail-down")
                        setTimeout(function () {
                            targetElement.style.display = 'none';
                        }, 300);
                    }
                }
            });
        });
    }
}
