export default class FormSubmit {
    constructor(id: string) {
        const form: HTMLElement = document.getElementById(id);
        if (form) {
            const submitBtn: HTMLInputElement = form.querySelector("input[type='submit']");
            if (submitBtn) {
                const icon = document.createElement("i");
                icon.classList.add("icon", "is-forward-arrow");
                submitBtn.parentElement.style.position = "relative";
                submitBtn.parentElement.appendChild(icon);
            }
        }
    }
}
