// Placeholder to import bootstrap types when needed
// import type { SomeType } from 'bootstrap';

//Components
import { Store } from "./components/store"
import ScrollableNav from "./components/scrollable-nav"
import Video from "./components/video"
import MultiVideo from "./components/multi-video"
import FormSubmit from "./components/form-submit"
import CardDropdown from "./components/card-dropdown"
import TimeLine from "./components/timeline"
import AccordionDropdown from "./components/accordion-dropdown"

class CoreSite {
    constructor() {

    }
}

export const Core: CoreSite = new CoreSite();
export { Store };
export { ScrollableNav };
export { Video };
export { MultiVideo };
export { FormSubmit };
export { CardDropdown };
export { TimeLine };
export { AccordionDropdown };