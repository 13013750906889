export default class AccordionDropdown {
    constructor(id: string) {
        const listContainer: HTMLElement = document.getElementById(id);
        listContainer.querySelectorAll('.dropdown-item').forEach((item: HTMLElement) => {
            item.addEventListener('click', function (e) {
                e.preventDefault();
                let selectedTab: string = item.getAttribute('data-tab-id');
                let tabLink: HTMLElement = document.querySelector('a[href="' + selectedTab + '"]');
                if (tabLink) {
                    tabLink.click();
                }
                listContainer.querySelector('.dropdown-toggle').textContent = item.textContent;
            });
        });
    }
}
