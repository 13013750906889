export default class Video {
    constructor(playId: string, videoId: string) {
        const EL_video: HTMLVideoElement = document.querySelector(videoId);
        const EL_play: HTMLButtonElement = document.querySelector(playId);
        const EL_overlay: HTMLElement = EL_play.parentElement;

        EL_play.addEventListener("click", () => {
            let isPaused: boolean = EL_video.paused;
            EL_play.classList.toggle("u-none", isPaused);
            EL_overlay.classList.toggle("overlay-hidden", isPaused); 
            EL_video[isPaused ? "play" : "pause"]();
        });

        EL_video.addEventListener("click", () => {
            let isPaused: boolean = EL_video.paused;
            EL_play.classList.toggle("u-none", isPaused);
            EL_overlay.classList.toggle("overlay-hidden", isPaused); 
            EL_video[isPaused ? "play" : "pause"]();
        });
    }
}
