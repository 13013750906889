import * as $ from 'jquery';
import 'slick-carousel';
export default class MultiVideo {
    private listContainer: HTMLElement | null;
    constructor(id: string) {
        this.listContainer = document.getElementById(id);
        this.initCarousel();
    }

    private initCarousel(): void {

        let images: HTMLElement = this.listContainer.querySelector(".multi-images");

        $(images).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            centerMode: true,
            asNavFor: '.multi-images-nav'
        });

        let imagesNav: HTMLElement = this.listContainer.querySelector(".multi-images-nav");

        $(imagesNav).slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: '.multi-images',
            dots: false,
            arrows: true,
            infinite: false,
            focusOnSelect: true,
            prevArrow: '<button type="button" class="slick-custom-arrow slick-prev"> <span class="visually-hidden">Prev</span> <i class="icon"></i> </button>',
            nextArrow: '<button type="button" class="slick-custom-arrow slick-next"> <span class="visually-hidden">Next</span> <i class="icon"></i> </button>',
            responsive: [
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 3,
                    }
                }
            ]
        });
    }
}
